import { defineStore } from 'pinia'

export const useNotificationsStore = defineStore('notifications', {
  state: () => ({
    notifications: []
  }),
  actions: {
    add(data) {
      const id = Math.random().toString(16).slice(2)
      const type = data.type
      const content = data.content
      const timeout = data.timeout || 5000
      const notification = { id, type, content }

      this.notifications.push(notification)

      setTimeout(() => {
        this.remove(id)
      }, timeout)
    },
    remove(id) {
      const notifications = [...this.notifications]
      const index = notifications.findIndex(n => n.id === id)
      if (index !== -1) {
        this.notifications.splice(index, 1) // hide the notification
      }
    },
  },
})