<template>
  <div>
    <Label
      :id="props.id"
      :required="props.required"
    >{{ props.label }}</Label>
    <textarea
      class="textarea"
      :name="props.id"
      :id="props.id"
      rows="10"
      :placeholder="props.placeholder"
      @input="onInput"
    ></textarea>
  </div>
</template>
<script setup>
const props = defineProps({
  modelValue: {
    type: String,
  },
  id: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
  },
  required: {
    type: Boolean,
  }
})

const emits = defineEmits(['update:modelValue'])
const onInput = (event) => {
  emits('update:modelValue', event.target.value) // update value
}

</script>
<style scoped>
.textarea {
  box-sizing: border-box;
  padding: var(--gap-xs);
  border: var(--input-border-width) solid var(--input-border-color);
  background-color: var(--input-background-color);
  height: 10rem;
  border-radius: var(--input-border-radius);
}
</style>