﻿<template>
  <ul class="menu" role="menu">
    <slot></slot>
  </ul>
</template>
<style scoped>
.menu {
  position: absolute;
  right: 50%;
  top: 100%;
  z-index: 1;
  transform: translateX(50%);
  white-space: nowrap;
  padding: var(--gap-xs);
  border: 1px solid rgba(var(--color-main-500-a), .2);
  box-shadow: 0 1px 5px 0 rgba(var(--color-main-500-a), .2);
  background-color: var(--color-main-50);
  opacity: 0;
  scale: 0;
  transition: opacity .3s;
}
[role="menuitem"][aria-expanded="true"] + .menu {
  opacity: 1;
  scale: 1;
}

@media (max-width: 420px) {
  .menu {
    left: var(--gap-xs);
    right: var(--gap-xs);
    transform: none;
  }
}
</style>