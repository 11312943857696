<template>

  <Head>
    <Title> | Geotur España</Title>
    <Meta name="description" content="Geotur - Concéntrese en el objetivo de su viaje. ¡Le aseguramos el éxito!" />
    <Link rel="manifest" href="/site.webmanifest" />
    <Link rel="preconnect" href="https://fonts.googleapis.com" />
    <Link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <Link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,700;1,300&family=Roboto&display=swap" rel="stylesheet" />
  </Head>

  <header class="header">
    <div class="header__content">
      <a href="/">
        <img src="/logo.svg" alt="Geotur" class="logo" width="90" height="66">
      </a>
      <nav class="navigation">
        <ul class="navigation__content">
          <NavItem to="/">Conócenos</NavItem>

          <NavItem class="nav-item-with-menu">
            Servicios
            <template #menu>
              <Menu>
                <NavItem to="/servicios/viajes-corporativos">Viajes corporativos</NavItem>
                <NavItem to="/servicios/grupos-e-incentivos">Grupos e incentivos</NavItem>
                <NavItem to="/servicios/reuniones-y-eventos">Reuniones y eventos</NavItem>
                <!-- <NavItem href="https://topatlantico.es">
                  Vacacional
                  <ClientOnly><FontAwesomeIcon icon="fa-arrow-up-right-from-square" /></ClientOnly>
                </NavItem> -->
              </Menu>
            </template>
          </NavItem>

          <NavItem class="nav-item-with-menu">
            Productos
            <template #menu>
              <Menu>
                <NavItem to="/productos/reservas-online">Reservas online</NavItem>
                <NavItem to="/productos/report">Report</NavItem>
                <NavItem to="/productos/facturacion-electronica">Facturación Electrónica</NavItem>
                <NavItem to="/productos/integracion-de-sistemas">Integración de sistemas</NavItem>
              </Menu>
            </template>
          </NavItem>

          <NavItem to="/contactos">Contactos</NavItem>
        </ul>
      </nav>
    </div>
  </header>
  <main class="main">
    <NuxtPage :transition="{
        name: 'bounce',
        mode: 'out-in'
      }" />
  </main>
  <ContactForm v-if="showContactForm" />
  <footer class="footer">
    <p class="footer__disclaimer">2023 © Todos los derechos reservados: VIAJES Y TURISMO DE GEOTUR ESPAÑA SL - C.I.F. : B-84402965 • C.I.C.MA 1744m</p>
    <div class="footer__logo">
      <img
        src="/images/community-partner-signature.png"
        alt="Community Partner Signature"
        width="260"
        height="81"
      >
    </div>
  </footer>

  <Notification
    v-for="notification in notifications"
    :type="notification.type"
    :id="notification.id"
  >{{ notification.content }}</Notification>

</template>
<script setup>
import { onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useNotificationsStore } from '@stores/notifications.js'

const route = useRoute()

const notificationsStore = useNotificationsStore()
const { notifications } = storeToRefs(notificationsStore)

const showContactForm = ref(null)

watch(() => route.name, value => {
  showContactForm.value = isRouteIndex(value) // hide contact form in index
})
onMounted(() => {
  showContactForm.value = isRouteIndex(route.name) // hide contact form in index
})

const isRouteIndex = (routeName) => {
  return routeName !== 'index'
}
</script>
<style scoped>
.header {
  position: relative;
  z-index: 2;
  width: 100%;
  height: var(--header-height);
  background-color: rgba(255,255,255,.6);
  margin-bottom: calc(var(--header-height) * -1);
}
.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: var(--content-width);
  height: 100%;
  margin: auto;
  padding: 0 var(--gap-sm);
}
.logo {
  height: 6.6rem;
}

.navigation__content {
  display: flex;
  gap: var(--gap);
}

/* Footer */
.footer {
  display: flex;
  align-items: center;
  gap: 0 var(--gap-sm);
  padding: var(--gap-sm);
  background-color: var(--color-neutral-900);
  color: #FFF;
}
.footer__disclaimer {
  flex-grow: 1;
  padding-left: 26rem; /* the width of footer__logo */
  font-size: 1rem;
  opacity: .5;
  text-align: center;
}


@media (max-width: 1023px) {
  .navigation__content {
    gap: var(--gap);
  }
  .footer__disclaimer {
    padding-left: 0;
  }
  .footer {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .navigation__content {
    gap: var(--gap-sm);
  }
}
@media (max-width: 650px) {
  .header {
    position: absolute;
    top: 0;
    height: auto;
    padding-top: var(--gap-xs);
  }
  .header__content {
    flex-direction: column;
  }
  .logo {
    height: 5rem;
  }
  .navigation__content {
    justify-content: center;
    flex-wrap: wrap;
    gap: 0;
    padding: var(--gap-xs) var(--gap-xs) 0;
    font-size: 1.2rem;
  }
}
</style>