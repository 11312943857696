﻿<template>
  <li role="presentation">
    <NuxtLink
      v-if="to"
      :to="to"
      class="item"
      role="menuitem"
    >
      <slot></slot>
    </NuxtLink>
    <a
      v-else-if="href"
      :href="href"
      class="item"
      role="menuitem"
    >
      <slot></slot>
    </a>
    <template v-else>
      <button
        type="button"
        class="item"
        @click="toggleMenu"
        role="menuitem"
        aria-haspopup="true"
        :aria-expanded="isMenuOpen"
      >
        <slot></slot>
        <ClientOnly><FontAwesomeIcon icon="fa-caret-down" /></ClientOnly>
      </button>
      <slot name="menu"></slot>
    </template>
  </li>
</template>
<script setup>
import { ref, onMounted } from 'vue'

defineProps({
  href: String,
  to: String,
})

const isOpen = ref(null)
const isMenuOpen = ref(false)

const toggleMenu = () => {
  isOpen.value = isMenuOpen.value
  setTimeout(() => {
    if (!isOpen.value) {
      isMenuOpen.value = true
    }
  }, 10);
}

onMounted(() => {
  document.addEventListener('click', (_event) => {
    isMenuOpen.value = false
  })
})

</script>
<style scoped>
li {
  position: relative;
}
.item {
  display: flex;
  align-items: center;
  gap: .5rem;
  padding: var(--gap-xs);
  text-decoration: none;
  font-family: var(--font-family-display);
  font-weight: 500;
  font-size: 1.7rem;
  cursor: pointer;
}
.router-link-active {
  color: var(--color-main-500);
  font-weight: 700;
}
</style>