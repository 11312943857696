<template>
  <div
    class="notification"
    :class="`notification--${props.type || 'success'}`"
  >
    <slot></slot>
    <button @click="close" class="notification__close">
    <ClientOnly><FontAwesomeIcon icon="fa-times" /></ClientOnly>
    </button>
  </div>
</template>
<script setup>
import { useNotificationsStore } from '@stores/notifications.js'
const notificationsStore = useNotificationsStore()
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    validator: value => {
      return ['success', 'error'].includes(value)
    },
  },
  content: {
    type: String,
  },
})

const close = () => {
  notificationsStore.remove(props.id)
}
</script>
<style scoped>
.notification {
  --width: 30rem;
  position: fixed;
  top: var(--gap-sm);
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  max-width: var(--width);
  padding: var(--gap) var(--gap-lg);
  z-index: 3;
  background-color: var(--color-neutral-500);
  opacity: .9;
  color: #FFF;
  font-size: 1.7rem;
  text-align: center;
}
.notification--success {
  background-color: var(--color-success-500);
}
.notification--error {
  background-color: var(--color-danger-500);
}
.notification__close {
  position: absolute;
  padding: var(--gap-sm);
  top: -.4rem;
  right: -.4rem;
  color: #FFF;
  font-size: 2rem;
  line-height: 0;
}
@media (max-width: 400px) {
  .notification {
    padding: var(--gap-sm) var(--gap);
  }
}
</style>