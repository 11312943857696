import { default as contactsXZEBtb6mQyMeta } from "/var/www/RasoDEV/Web/Geotur.es/Source/geotur.es/pages/contacts.vue?macro=true";
import { default as index2hBggvZBfgMeta } from "/var/www/RasoDEV/Web/Geotur.es/Source/geotur.es/pages/index.vue?macro=true";
import { default as billingbGI5jXCHvPMeta } from "/var/www/RasoDEV/Web/Geotur.es/Source/geotur.es/pages/product/billing.vue?macro=true";
import { default as bookingsOfWGNDMdCmMeta } from "/var/www/RasoDEV/Web/Geotur.es/Source/geotur.es/pages/product/bookings.vue?macro=true";
import { default as integrationsGyzdZX8WPVMeta } from "/var/www/RasoDEV/Web/Geotur.es/Source/geotur.es/pages/product/integrations.vue?macro=true";
import { default as reportQelork6hwvMeta } from "/var/www/RasoDEV/Web/Geotur.es/Source/geotur.es/pages/product/report.vue?macro=true";
import { default as corporateYHt9tQH1sGMeta } from "/var/www/RasoDEV/Web/Geotur.es/Source/geotur.es/pages/services/corporate.vue?macro=true";
import { default as groupsg1nh2Uj93OMeta } from "/var/www/RasoDEV/Web/Geotur.es/Source/geotur.es/pages/services/groups.vue?macro=true";
import { default as meetingsjE8gF4WmLEMeta } from "/var/www/RasoDEV/Web/Geotur.es/Source/geotur.es/pages/services/meetings.vue?macro=true";
export default [
  {
    name: contactsXZEBtb6mQyMeta?.name ?? "contacts",
    path: contactsXZEBtb6mQyMeta?.path ?? "/contacts",
    meta: contactsXZEBtb6mQyMeta || {},
    alias: contactsXZEBtb6mQyMeta?.alias || [],
    redirect: contactsXZEBtb6mQyMeta?.redirect || undefined,
    component: () => import("/var/www/RasoDEV/Web/Geotur.es/Source/geotur.es/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: index2hBggvZBfgMeta?.name ?? "index",
    path: index2hBggvZBfgMeta?.path ?? "/",
    meta: index2hBggvZBfgMeta || {},
    alias: index2hBggvZBfgMeta?.alias || [],
    redirect: index2hBggvZBfgMeta?.redirect || undefined,
    component: () => import("/var/www/RasoDEV/Web/Geotur.es/Source/geotur.es/pages/index.vue").then(m => m.default || m)
  },
  {
    name: billingbGI5jXCHvPMeta?.name ?? "product-billing",
    path: billingbGI5jXCHvPMeta?.path ?? "/product/billing",
    meta: billingbGI5jXCHvPMeta || {},
    alias: billingbGI5jXCHvPMeta?.alias || [],
    redirect: billingbGI5jXCHvPMeta?.redirect || undefined,
    component: () => import("/var/www/RasoDEV/Web/Geotur.es/Source/geotur.es/pages/product/billing.vue").then(m => m.default || m)
  },
  {
    name: bookingsOfWGNDMdCmMeta?.name ?? "product-bookings",
    path: bookingsOfWGNDMdCmMeta?.path ?? "/product/bookings",
    meta: bookingsOfWGNDMdCmMeta || {},
    alias: bookingsOfWGNDMdCmMeta?.alias || [],
    redirect: bookingsOfWGNDMdCmMeta?.redirect || undefined,
    component: () => import("/var/www/RasoDEV/Web/Geotur.es/Source/geotur.es/pages/product/bookings.vue").then(m => m.default || m)
  },
  {
    name: integrationsGyzdZX8WPVMeta?.name ?? "product-integrations",
    path: integrationsGyzdZX8WPVMeta?.path ?? "/product/integrations",
    meta: integrationsGyzdZX8WPVMeta || {},
    alias: integrationsGyzdZX8WPVMeta?.alias || [],
    redirect: integrationsGyzdZX8WPVMeta?.redirect || undefined,
    component: () => import("/var/www/RasoDEV/Web/Geotur.es/Source/geotur.es/pages/product/integrations.vue").then(m => m.default || m)
  },
  {
    name: reportQelork6hwvMeta?.name ?? "product-report",
    path: reportQelork6hwvMeta?.path ?? "/product/report",
    meta: reportQelork6hwvMeta || {},
    alias: reportQelork6hwvMeta?.alias || [],
    redirect: reportQelork6hwvMeta?.redirect || undefined,
    component: () => import("/var/www/RasoDEV/Web/Geotur.es/Source/geotur.es/pages/product/report.vue").then(m => m.default || m)
  },
  {
    name: corporateYHt9tQH1sGMeta?.name ?? "services-corporate",
    path: corporateYHt9tQH1sGMeta?.path ?? "/services/corporate",
    meta: corporateYHt9tQH1sGMeta || {},
    alias: corporateYHt9tQH1sGMeta?.alias || [],
    redirect: corporateYHt9tQH1sGMeta?.redirect || undefined,
    component: () => import("/var/www/RasoDEV/Web/Geotur.es/Source/geotur.es/pages/services/corporate.vue").then(m => m.default || m)
  },
  {
    name: groupsg1nh2Uj93OMeta?.name ?? "services-groups",
    path: groupsg1nh2Uj93OMeta?.path ?? "/services/groups",
    meta: groupsg1nh2Uj93OMeta || {},
    alias: groupsg1nh2Uj93OMeta?.alias || [],
    redirect: groupsg1nh2Uj93OMeta?.redirect || undefined,
    component: () => import("/var/www/RasoDEV/Web/Geotur.es/Source/geotur.es/pages/services/groups.vue").then(m => m.default || m)
  },
  {
    name: meetingsjE8gF4WmLEMeta?.name ?? "services-meetings",
    path: meetingsjE8gF4WmLEMeta?.path ?? "/services/meetings",
    meta: meetingsjE8gF4WmLEMeta || {},
    alias: meetingsjE8gF4WmLEMeta?.alias || [],
    redirect: meetingsjE8gF4WmLEMeta?.redirect || undefined,
    component: () => import("/var/www/RasoDEV/Web/Geotur.es/Source/geotur.es/pages/services/meetings.vue").then(m => m.default || m)
  }
]