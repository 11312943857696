<template>
  <div>
    <Label :id="props.id" :required="props.required">{{ props.label }}</Label>
    <input
      class="input"
      :class="{'input--error': errorMessage}"
      :id="props.id"
      :type="props.text"
      :placeholder="props.placeholder"
      :value="modelValue"
      @input="onInput"
    >
    <p v-if="errorMessage" class="input__error-message">{{ errorMessage }}</p>
  </div>
</template>
<script setup>

import { ref, watch } from 'vue'

const emits = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    type: String,
  },
  id: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: 'text'
  },
  label: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
  },
  required: {
    type: Boolean,
  },
  error: {
    type: String,
  }
})

const errorMessage = ref('')

watch(() => props.error, (value) => {
  errorMessage.value = value
})

const onInput = (event) => {
  errorMessage.value = '' // reset error message
  emits('update:modelValue', event.target.value) // update value
}

</script>
<style scoped>
.input {
  box-sizing: border-box;
  padding: 0 0 0 var(--gap-xs);
  border: var(--input-border-width) solid var(--input-border-color);
  background-color: var(--input-background-color);
  height: var(--input-height);
  border-radius: var(--input-border-radius);
}
.input--error {
  border-color: var(--color-danger-500);
}
.input__error-message {
  color: var(--color-danger-500);
}
</style>